import React from "react"
import ServiceContent from "../components/Organisms/StaticSections/ServiceContent"
import ServiceContact from "../components/Organisms/StaticSections/ServiceContact"
import ContactForm from "../components/Molecules/ContactForm/ContactForm"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"
import {WithIntl} from "../components/Atoms/withTranslate"

const ServicePage = ({language}) => (
  <>
    <SEO
      lang={language}
      title={localize("303")}
      description={localize("310")}
    />
    <div className="container">
      <ServiceContent/>
      <ServiceContact/>
      <ContactForm emailTo="serwis@mywash.pl"/>
    </div>
  </>
)

export default WithIntl()(ServicePage)
