import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import hourImage from "../../../assets/images/icons/24hours.svg"
import calendarImage from "../../../assets/images/icons/7days.svg"
import { localize } from "../../Atoms/Language"

const StyledSection = styled.section`
  padding: 80px 0;
  border-bottom: 1px solid #d3dbe6;
`

const StyledSubHeader = styled.span`
  display: block;
  font-family: Teko;
  text-transform: uppercase;
  color: ${GlobalColors.gray500};
`

const StyledButtonWrapper = styled.div`
  margin: 36px 0 60px;

  span {
    display: inline-block;
    margin: 0 40px;
    color: ${GlobalColors.gray500};
    font-size: 18px;
    font-weight: 600;
    font-family: Teko;
    text-transform: uppercase;

    @media screen and (max-width: 750px) {
      margin: 0 10px;
    }
  }
`

const StyledPhoneNumber = styled.a`
  display: inline-block;
  background: ${GlobalColors.primary};
  color: ${GlobalColors.white};
  padding: 15px 24px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.8rem;
  font-family: Teko;
  font-weight: 600;
`

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    flex: 1;
    padding-left: 90px;
    text-transform: uppercase;
    font-family: Teko;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.2rem;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 64px;

    span {
      display: block;
      max-width: 240px;
    }
  }

  .hours {
    background-image: url(${hourImage});
  }

  .days {
    background-image: url(${calendarImage});
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
    align-items: flex-start;
    div {
      background-size: 50px;
      margin-bottom: 40px;
    }
  }
`

const ServiceContact = () => (
  <StyledSection>
    <h2 className="header3">{localize("306")}</h2>
    <StyledSubHeader className="header3">{localize("307")}</StyledSubHeader>
    <StyledButtonWrapper>
      <StyledPhoneNumber href="tel:+48572956655">
        +48 572 956 655
      </StyledPhoneNumber>
      <span>lub</span>
      <StyledPhoneNumber href="tel:+48574944344">
        +48 574 944 344
      </StyledPhoneNumber>
    </StyledButtonWrapper>
    <StyledInfoWrapper>
      <div className="days">
        <span>{localize("309")}</span>
      </div>
    </StyledInfoWrapper>
  </StyledSection>
)

export default ServiceContact
