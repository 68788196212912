import React from "react"
import styled from "styled-components"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

export const imageQuery = graphql`
  {
    file(name: { eq: "serwis" }) {
      childImageSharp {
        fixed(width: 540, quality: 90){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledImageWithText = styled(ImageWithText)`
  border-bottom: 1px solid ${GlobalColors.gray300};
`

const ServiceContent = () => {
  const data = useStaticQuery(imageQuery)
  return (
    <StyledImageWithText className="mt8 pb16" fixed={data.file.childImageSharp.fixed} imageAltText={localize("9")}>
      <h1 className="header2">{localize("303")}</h1>
      <p className="body">{localize("304")}</p>
      <p className="body">{localize("305")}</p>
    </StyledImageWithText>
  )
}
export default ServiceContent
